.App {
  /* text-align: center; */
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #61dafb;
  font-size: 24px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#quote-box {
  /* margin: 20px auto; */
  color: white;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border: 1px solid green;
  background-color: green;
  padding: 20px;
  border: none;
  border-radius: 3px;
}

#new-quote {
  padding: 10px 20px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  background-color: gold;
  font-size: large;
}

.tweet-section {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

#tweet-quote {
  color: white;
  text-decoration: none;
  /* background-color: orange; */
  border: 1px solid gold;
  padding: 9px 19px;
  font-size: smaller;
}
